header {
  background-color: #222e32;
  color: #ECEFF1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  height: 4rem;
  margin: 1rem;
  position: fixed;
  z-index: 5;
  max-width: 100%;
}
header .logo-img {
  width: 12rem;
}

@media screen and (max-width: 600px) {
  header .logo-img {
    display: none;
  }
  header .btn {
    margin: 0.3em;
  }
  header .font-awesome-btn {
    margin: 0.3em;
  }
}/*# sourceMappingURL=header.css.map */