$highlight-bright: #29B6F6;
$highlight-dark: #0e6181;
$background-dark: #1e282c;
$background-bright: #222e32;
$font-color: #ECEFF1;

  
  .background {
    width: 100%;
    height: 100vh;
    background: radial-gradient(circle, $background-bright 0%, $background-bright 35%, $background-dark 100%);
    overflow: hidden;
    position: fixed;
    z-index: -1;
  }
  
  $particleSize: 20vmin;
  $animationDuration: 6s;
  $amount: 20;
  .background span {
    width: $particleSize;
    height: $particleSize;
    border-radius: $particleSize;
    backface-visibility: hidden;
    position: absolute;
    animation-name: move;
    animation-duration: $animationDuration;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    $colors: (
      $highlight-bright,
      $highlight-dark,
      $font-color
    );
    @for $i from 1 through $amount {
      &:nth-child(#{$i}) {
        color: nth($colors, random(length($colors)));
        top: random(100) * 1%;
        left: random(100) * 1%;
        animation-duration: (random($animationDuration * 10) / 10) * 1s + 10s;
        animation-delay: random(($animationDuration + 10s) * 10) / 10 * -1s;
        transform-origin: (random(50) - 25) * 1vw (random(50) - 25) * 1vh;
        $blurRadius: (random() + 0.5) * $particleSize * 0.5;
        $x: if(random() > 0.5, -1, 1);
        box-shadow: ($particleSize * 2 * $x) 0 $blurRadius currentColor;
      }
    }
  }
  
  @keyframes move {
    100% {
      transform: translate3d(0, 0, 1px) rotate(360deg);
    }
  }