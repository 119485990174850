$highlight-bright: #29B6F6;
$highlight-dark: #03A9F4;
$background-dark: #1e282c;
$background-bright: #222e32;
$font-color: #ECEFF1;

header
{
    background-color: $background-bright;
    color: $font-color;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    height: 4rem;
    margin: 1rem;
    position: fixed;
    z-index: 5;
    max-width: 100%;

    .logo-img
    {
        width: 12rem;
    }
}

// if on mobile hide logo
@media screen and (max-width: 600px) {
    
    header 
    { 
        .logo-img
        {
            display: none;
        }
        
        .btn
        {
            margin: 0.3em;
        }

        .font-awesome-btn {
            margin: 0.3em;
        }
    }
}