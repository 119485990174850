.team-holder {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  align-content: center;
}
.team-holder .team-member {
  margin: 0.5em;
  margin-top: 1em;
  margin-bottom: 1em;
  max-width: 20em;
  width: 100%;
}
.team-holder .team-member .team-member-name {
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;
}
.team-holder .team-member .team-member-title {
  font-size: 1em;
  text-align: center;
  padding-bottom: 0.5em;
}
.team-holder .team-member .team-member-img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}/*# sourceMappingURL=team.css.map */