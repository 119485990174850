.background {
  width: 100%;
  height: 100vh;
  background: radial-gradient(circle, #222e32 0%, #222e32 35%, #1e282c 100%);
  overflow: hidden;
  position: fixed;
  z-index: -1;
}

.background span {
  width: 20vmin;
  height: 20vmin;
  border-radius: 20vmin;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  position: absolute;
  animation-name: move;
  animation-duration: 6s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
.background span:nth-child(1) {
  color: #0e6181;
  top: 36%;
  left: 88%;
  animation-duration: 10.6s;
  animation-delay: -2.5s;
  transform-origin: 25vw -13vh;
  box-shadow: 40vmin 0 10.397081743vmin currentColor;
}
.background span:nth-child(2) {
  color: #29B6F6;
  top: 56%;
  left: 65%;
  animation-duration: 11.6s;
  animation-delay: -1.2s;
  transform-origin: -10vw -17vh;
  box-shadow: -40vmin 0 14.6258695113vmin currentColor;
}
.background span:nth-child(3) {
  color: #ECEFF1;
  top: 65%;
  left: 27%;
  animation-duration: 12.6s;
  animation-delay: -9.4s;
  transform-origin: -19vw 23vh;
  box-shadow: -40vmin 0 12.3026648922vmin currentColor;
}
.background span:nth-child(4) {
  color: #29B6F6;
  top: 90%;
  left: 1%;
  animation-duration: 14s;
  animation-delay: -6.5s;
  transform-origin: -12vw 20vh;
  box-shadow: -40vmin 0 10.1613332337vmin currentColor;
}
.background span:nth-child(5) {
  color: #0e6181;
  top: 83%;
  left: 8%;
  animation-duration: 14.5s;
  animation-delay: -14.8s;
  transform-origin: -8vw 5vh;
  box-shadow: 40vmin 0 8.9248938904vmin currentColor;
}
.background span:nth-child(6) {
  color: #29B6F6;
  top: 98%;
  left: 38%;
  animation-duration: 15.5s;
  animation-delay: -1.1s;
  transform-origin: -10vw -22vh;
  box-shadow: 40vmin 0 12.8571397071vmin currentColor;
}
.background span:nth-child(7) {
  color: #29B6F6;
  top: 41%;
  left: 13%;
  animation-duration: 13.5s;
  animation-delay: -0.4s;
  transform-origin: 3vw -6vh;
  box-shadow: -40vmin 0 9.5560748571vmin currentColor;
}
.background span:nth-child(8) {
  color: #ECEFF1;
  top: 71%;
  left: 75%;
  animation-duration: 11.2s;
  animation-delay: -11.1s;
  transform-origin: -11vw 11vh;
  box-shadow: -40vmin 0 5.2786170754vmin currentColor;
}
.background span:nth-child(9) {
  color: #ECEFF1;
  top: 25%;
  left: 77%;
  animation-duration: 10.2s;
  animation-delay: -2.5s;
  transform-origin: 9vw -7vh;
  box-shadow: -40vmin 0 12.980888149vmin currentColor;
}
.background span:nth-child(10) {
  color: #0e6181;
  top: 11%;
  left: 44%;
  animation-duration: 13.2s;
  animation-delay: -7.4s;
  transform-origin: -16vw -6vh;
  box-shadow: 40vmin 0 7.4751118276vmin currentColor;
}
.background span:nth-child(11) {
  color: #0e6181;
  top: 99%;
  left: 28%;
  animation-duration: 14s;
  animation-delay: -11.1s;
  transform-origin: 10vw 3vh;
  box-shadow: -40vmin 0 7.5261002692vmin currentColor;
}
.background span:nth-child(12) {
  color: #ECEFF1;
  top: 38%;
  left: 41%;
  animation-duration: 14.3s;
  animation-delay: -15s;
  transform-origin: 15vw 11vh;
  box-shadow: -40vmin 0 7.4764970101vmin currentColor;
}
.background span:nth-child(13) {
  color: #0e6181;
  top: 65%;
  left: 24%;
  animation-duration: 15.4s;
  animation-delay: -11.8s;
  transform-origin: 10vw 7vh;
  box-shadow: -40vmin 0 10.4596955749vmin currentColor;
}
.background span:nth-child(14) {
  color: #29B6F6;
  top: 1%;
  left: 41%;
  animation-duration: 15.7s;
  animation-delay: -14.1s;
  transform-origin: 4vw -13vh;
  box-shadow: 40vmin 0 12.4329710979vmin currentColor;
}
.background span:nth-child(15) {
  color: #ECEFF1;
  top: 23%;
  left: 85%;
  animation-duration: 10.7s;
  animation-delay: -7.9s;
  transform-origin: 2vw 22vh;
  box-shadow: 40vmin 0 13.6503347364vmin currentColor;
}
.background span:nth-child(16) {
  color: #29B6F6;
  top: 6%;
  left: 16%;
  animation-duration: 12.8s;
  animation-delay: -11.5s;
  transform-origin: 24vw 12vh;
  box-shadow: 40vmin 0 5.9017364452vmin currentColor;
}
.background span:nth-child(17) {
  color: #ECEFF1;
  top: 56%;
  left: 30%;
  animation-duration: 12.1s;
  animation-delay: -13.9s;
  transform-origin: -5vw -23vh;
  box-shadow: -40vmin 0 7.6307918177vmin currentColor;
}
.background span:nth-child(18) {
  color: #29B6F6;
  top: 49%;
  left: 82%;
  animation-duration: 11.2s;
  animation-delay: -0.9s;
  transform-origin: -3vw 0vh;
  box-shadow: -40vmin 0 5.3156197695vmin currentColor;
}
.background span:nth-child(19) {
  color: #29B6F6;
  top: 36%;
  left: 11%;
  animation-duration: 15.6s;
  animation-delay: -10.7s;
  transform-origin: 1vw -14vh;
  box-shadow: -40vmin 0 14.8514679589vmin currentColor;
}
.background span:nth-child(20) {
  color: #ECEFF1;
  top: 5%;
  left: 65%;
  animation-duration: 12.1s;
  animation-delay: -10.2s;
  transform-origin: -21vw -6vh;
  box-shadow: 40vmin 0 10.121983059vmin currentColor;
}

@keyframes move {
  100% {
    transform: translate3d(0, 0, 1px) rotate(360deg);
  }
}/*# sourceMappingURL=background.css.map */