.team-holder {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    align-content: center;
    .team-member {
        margin: 0.5em;
        margin-top: 1em;
        margin-bottom: 1em;
        max-width: 20em;
        width: 100%;
        .team-member-name {
            font-size: 1.5em;
            font-weight: bold;
            text-align: center;
        }
        .team-member-title {
            font-size: 1em;
            text-align: center;
            padding-bottom: 0.5em;
        }
        .team-member-img {
                width: 100%;
                height: 100%;
                object-fit: cover;
        }
    }
}