$highlight-bright: #29B6F6;
$highlight-dark: #03A9F4;
$background-dark: #1e282c;
$background-bright: #222e32;
$font-color: #ECEFF1;

* {
    margin: 0;
    padding: 0;
    color: $font-color;
    scroll-behavior: smooth;   
}


/* Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: #03A9F4 #222E32;
  }
  
  /* Chrome, Edge and Safari */
  *::-webkit-scrollbar {
    width: 7px;
    width: 7px;
  }
  *::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: #222E32;
  }
  
  *::-webkit-scrollbar-track:hover {
    background-color: #1E282C;
  }
  
  *::-webkit-scrollbar-track:active {
    background-color: #1E282C;
  }
  
  *::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: #03A9F4;
  }
  
  *::-webkit-scrollbar-thumb:hover {
    background-color: #29B6F6;
  }
  
  *::-webkit-scrollbar-thumb:active {
    background-color: #10CCF6;
  }
  

  :root
  {
    overflow-x: hidden;
  }

body {
    background-color: $background-dark;
    background: radial-gradient(circle, $background-bright 0%, $background-bright 35%, $background-dark 100%);
    // add a monospace font
    font-family: 'monospace', sans-serif;
    min-height: 100vh;
    width: 100%;
    overflow-x: hidden;
}


.main-content {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: 100vh;
    width: 100%;
    background-position: fixed;
    color: $font-color;
}

p {
    max-width: 30em;
    padding: 1em;
}

h1 {
    font-size: 2em;
}

@media screen and (max-width: 600px) {
    * {
        font-size: 95%;
    }
}



