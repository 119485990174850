.neon-border {
  border: 1px solid #29B6F6;
  border-radius: 5px;
  box-shadow: 0 0 5px #29B6F6;
}

.full-screen-holder {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 3em;
  margin-bottom: 3em;
}

.side-by-side {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
}

.btn {
  color: #ECEFF1;
  text-shadow: #03A9F4 0px 0px 5px, #29B6F6 0px 0px 10px;
  pointer-events: pointer;
  text-decoration: none;
  padding: 0.5rem;
  margin: 1rem;
  font-size: 1em;
  background-color: #222e32;
  transition: 0.25s;
  font-weight: bold;
}

.btn:hover {
  color: #ECEFF1;
  text-shadow: #03A9F4 0px 0px 5px, #29B6F6 0px 0px 10px, #29B6F6 0px 0px 15px;
  background-color: #1e282c;
  border-color: #03A9F4;
  box-shadow: 0 0 10px #03A9F4;
  transition: 0.1s;
  scale: 1.1;
  background-color: #03A9F4;
}

.btn:active {
  color: #ECEFF1;
  text-shadow: #03A9F4 0px 0px 5px, #29B6F6 0px 0px 10px, #29B6F6 0px 0px 15px;
  background-color: #1e282c;
  border-color: #03A9F4;
  box-shadow: 0 0 10px #03A9F4;
  background-color: #03A9F4;
  transition: 0.05s;
  scale: 1.15;
}

.btn:disabled {
  color: #ECEFF1;
  text-shadow: 0 0 0 #03A9F4, 0 0 0 #29B6F6;
  border-color: #03A9F4;
  box-shadow: 0 0 0 0;
  background-color: #222e32;
  filter: saturate(0.1);
  transition: 0.05s;
  scale: 1;
}

.font-awesome-btn {
  color: #ECEFF1;
  text-shadow: #03A9F4 0px 0px 5px, #29B6F6 0px 0px 10px;
  text-decoration: none;
  padding: 0.5rem;
  margin: 1rem;
  font-size: 1em;
  background-color: none;
  transition: 0.25s;
  font-weight: bold;
}
.font-awesome-btn .neon {
  scale: 1.5;
  filter: drop-shadow(0 0 0.2rem #03A9F4) drop-shadow(0 0 0.2rem #29B6F6);
}

.font-awesome-btn:hover {
  color: #ECEFF1;
  text-shadow: #03A9F4 0px 0px 5px, #29B6F6 0px 0px 10px, #29B6F6 0px 0px 15px;
  background-color: none;
  transition: 0.1s;
  scale: 1.25;
}

.font-awesome-btn:active {
  color: #ECEFF1;
  text-shadow: #03A9F4 0px 0px 5px, #29B6F6 0px 0px 10px, #29B6F6 0px 0px 15px;
  background-color: none;
  box-shadow: 0 0 10px #03A9F4;
  transition: 0.05s;
  scale: 1.4;
}

.font-awesome-btn:disabled {
  color: #ECEFF1;
  text-shadow: 0 0 0 #03A9F4, 0 0 0 #29B6F6;
  border-color: #03A9F4;
  box-shadow: 0 0 0 0;
  background-color: none;
  filter: saturate(0.1);
  transition: 0.05s;
  scale: 1;
}

.title {
  color: #ECEFF1;
  text-shadow: #03A9F4 0px 0px 5px, #29B6F6 0px 0px 10px, #29B6F6 0px 0px 15px;
  text-decoration: none;
  margin: 1rem;
  font-weight: bold;
}

.neon {
  color: #ECEFF1;
  text-shadow: #03A9F4 0px 0px 5px, #29B6F6 0px 0px 10px, #29B6F6 0px 0px 15px;
  text-decoration: none;
  font-weight: bold;
}

.centered {
  text-align: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.transparent-bg {
  background-color: rgba(30, 40, 44, 0.6);
}

.big {
  font-size: 2em;
}

.marginless-top {
  margin-top: 0;
}

.marginless-bottom {
  margin-bottom: 0;
}

.marginless {
  margin: 0;
}/*# sourceMappingURL=common.css.map */